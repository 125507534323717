import styled, { css, keyframes } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const fadeUpKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const fadeInKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const springKeyframes = keyframes`
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
`;

/**
 * fade-up-animation(className) + useIntersectionObserver 과 함께 사용
 */
const fadeUpAnimation = css`
  .fade-up-animation {
    opacity: 0;
    transform: translateY(100px);

    &.active {
      animation-name: ${fadeUpKeyframes};
      animation-duration: 0.8s;
      animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
      animation-fill-mode: forwards;
    }
  }
`;

/**
 * fade-in-and-spring-animation(className) + useIntersectionObserver 과 함께 사용
 */
const fadeInAndSpringAnimation = css`
  .fade-in-and-spring-animation {
    opacity: 0;
    transform: translateY(100px);

    &.active {
      animation-name: ${fadeInKeyframes}, ${springKeyframes};
      animation-duration: 0.8s, 0.8s;
      animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1),
        cubic-bezier(0.54, 1.62, 0.43, 0.92);
      animation-fill-mode: forwards;
    }
  }
`;

/**
 * fade-in-animation(className) + useIntersectionObserver 과 함께 사용
 */
const fadeInAnimation = css`
  .fade-in-animation {
    opacity: 0;

    &.active {
      animation-name: ${fadeInKeyframes};
      animation-duration: 0.8s;
      animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
      animation-fill-mode: forwards;
    }
  }
`;

const container = styled.div`
  position: relative;
  width: 100%;
  min-width: 1280px;

  ${mobile(css`
    min-width: 100%;
  `)};

  .mobile-register-button {
    ${mobile(css`
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    `)};
  }
`;

/**
 * SG쉽다 > 특정 OS + 브라우저에서 의도하지 않은 개행 발생
 * SG쉽다 > 랜딩 > 최상단 문구는 font-size, line-height을 커스터마이징 하기로 협의(상유님)
 */
const sectionTitle = styled.h3<{
  textColor?: string;
}>`
  ${setFontStyle("Head3")};
  color: ${({ textColor }) => textColor ?? TEXT_COLOR.black_2};

  ${mobile(css`
    ${setFontStyle("Head6")};
  `)};
`;

const sectionDesc = styled.p<{ textColor?: string }>`
  margin-top: 16px;
  ${setFontStyle("Body2")};
  color: ${({ textColor }) => textColor ?? TEXT_COLOR.black_2};

  > strong {
    color: ${COLOR.primary_600};
  }

  ${mobile(css`
    ${setFontStyle("Body3")};
  `)};
`;

export {
  fadeUpKeyframes,
  fadeUpAnimation,
  fadeInAndSpringAnimation,
  fadeInAnimation,
};

export default {
  container,
  sectionTitle,
  sectionDesc,
};
