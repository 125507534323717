import { useMemo } from "react";
import { useRouter } from "next/router";

import { SERVICE_INTRODUCTION_INQUIRY_URL } from "@sellernote/_shared/src/constants";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import BoxesIcon from "@sellernote/_sds-v2/src/components/svgIcons/BoxesIcon";
import MailIcon from "@sellernote/_sds-v2/src/components/svgIcons/MailIcon";
import Plane2Icon from "@sellernote/_sds-v2/src/components/svgIcons/Plane2Icon";
import Plane3Icon from "@sellernote/_sds-v2/src/components/svgIcons/Plane3Icon";
import ShipIcon from "@sellernote/_sds-v2/src/components/svgIcons/ShipIcon";
import TruckMoving2Icon from "@sellernote/_sds-v2/src/components/svgIcons/TruckMoving2Icon";
import WritingIcon from "@sellernote/_sds-v2/src/components/svgIcons/WritingIcon";

import useCheckIsFulfillmentPage from "../../../hooks/common/useCheckIsFulfillmentPage";
import { useTranslation } from "../../../utils/i18n";

import QuickActionsForDesktop from "./QuickActionsForDesktop";
import QuickActionsForMobile from "./QuickActionsForMobile";

export interface QuickActionItem {
  label: string;
  path?: string;
  Icon: (props: React.SVGAttributes<SVGElement>) => JSX.Element;
  isExternalLink?: boolean;
  handleClick?: () => void;
}

const FULFILLMENT_QUICK_ACTION: Record<
  "domestic" | "overseas" | "event",
  QuickActionItem
> = {
  domestic: {
    label: "국내 출고",
    path: "/fulfillment/domestic",
    Icon: TruckMoving2Icon,
  },
  overseas: {
    label: "해외 출고",
    path: "/fulfillment/overseas",
    Icon: Plane3Icon,
  },
  event: {
    label: "이벤트 출고",
    path: "/fulfillment/event",
    Icon: BoxesIcon,
  },
};

const getFulfillmentQuickActionList = (asPath: string) => {
  const pageName = asPath.split("/").pop();

  return Object.entries(FULFILLMENT_QUICK_ACTION).reduce<QuickActionItem[]>(
    (prev, [key, value]) => {
      if (key !== pageName) {
        return [...prev, value];
      }

      return prev;
    },
    []
  );
};

export default function LandingQuickActions({
  openServiceGuideModal,
}: {
  openServiceGuideModal?: () => void;
}) {
  const { isFulfillmentPage } = useCheckIsFulfillmentPage();

  const { t } = useTranslation(["main-landing"]);

  const { isMobile } = useCheckIsMobile();

  const asPath = useRouter().asPath;

  const fulfillmentQuickActionList = useMemo(() => {
    if (!isFulfillmentPage) {
      return [];
    }

    return getFulfillmentQuickActionList(asPath);
  }, [asPath, isFulfillmentPage]);

  const quickActionList: QuickActionItem[] = useMemo(() => {
    return [
      ...(isMobile
        ? [
            ...(openServiceGuideModal
              ? [
                  {
                    label: isFulfillmentPage
                      ? "풀필먼트 소개서 요청"
                      : "쉽다 소개서 요청",
                    Icon: MailIcon,
                    isExternalLink: true,
                    handleClick: openServiceGuideModal,
                  },
                ]
              : []),
            {
              label: t("바로가기버튼_도입문의"),
              path: SERVICE_INTRODUCTION_INQUIRY_URL,
              Icon: WritingIcon,
              isExternalLink: true,
            },
          ]
        : []),
      ...(isFulfillmentPage
        ? fulfillmentQuickActionList
        : [
            {
              label: t("바로가기버튼_해상운임조회"),
              path: "/forwarding/ocean/order",
              Icon: ShipIcon,
            },
            {
              label: t("바로가기버튼_항공운임조회"),
              path: "/forwarding/air/order",
              Icon: Plane2Icon,
            },
          ]),
    ];
  }, [
    fulfillmentQuickActionList,
    isFulfillmentPage,
    isMobile,
    openServiceGuideModal,
    t,
  ]);

  return (
    <>
      {isMobile ? (
        <QuickActionsForMobile list={quickActionList} />
      ) : (
        <QuickActionsForDesktop list={quickActionList} />
      )}
    </>
  );
}
